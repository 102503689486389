











































































import { Vue, Component, Prop } from "vue-property-decorator";
import { TransferRequestInfoProperties } from './TransferRequestInfoView.vue';
import { AlertDialogProperties } from '@/components/shared/AlertDialogView.vue';
import ExceptionUtil from '@/utilities/ExceptionUtil';
import TransferRequestDetailService from '@/services/TransferRequestDetailService';
import DateUtil from '@/utilities/DateUtil';

const TransferRequestProductView = () => import("./TransferRequestProductView.vue");

@Component({
    components: { TransferRequestProductView }
})
export default class TransferRequestDetailView extends Vue {
    @Prop() private properties: TransferRequestInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private transferRequestDetail = new TransferRequestDetailService();

    public get request() {
        return this.properties.request;
    }

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.properties.dateTimeFormat) : "";
    }

    public formatAmount(v: any) {
        const symbol = this.properties.currency.symbol ?? "";
        return symbol + " " + v.toFixed(2);
    }

    public created() {
        this.load();
        this.properties.events.subscribe('load-details', this.load);
    }

    public destroyed() {
        this.properties.events.remove('load-details', this.load);
    }

    public async load() {
        try {
            if (this.request.id) {
                const r = await this.transferRequestDetail.current({
                    transferRequestId: this.request.id,
                    loadCustomerOrder: true
                });
                const details = r.data.transferRequestDetails;
                this.properties.details = details.sort((lhs, rhs) => rhs.id - lhs.id);
            } else {
                this.properties.details = [];
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null,  m);
                this.alertDialog.visible = true;
            }
        }
    }

    public expand(detail: any) {
        const expanded = detail.customerOrder.expanded;
        Vue.set(detail.customerOrder, "expanded", !(expanded ?? false));
    }
}
